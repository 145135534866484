/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
import './css/normalize.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.min.css'
import 'tarteaucitronjs/css/tarteaucitron.css';
import 'pe7-icon/dist/dist/pe-icon-7-stroke.css';
import './css/slickmap.css';
import './css/global.css';
import './css/preview.css';

const $ = require('jquery');
// create global $ and jQuery variables
global.$ = global.jQuery = $;

import 'owl.carousel';
import 'chart.js/dist/Chart.min';
import 'popper.js';
import 'bootstrap';
import 'tarteaucitronjs/tarteaucitron.js';
import 'jquery.cookie';
import './js/global.js';
