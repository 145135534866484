const Chart = require("chart.js/dist/Chart.min");

$(document).ready(function () {
	//credits
	var _cA = [
		"\n %c ── %c by %c LORWEB %c ── %c http://www.lorweb.com/ \n\n",
		"color: #4e9cc3;",
		'font-size:10px; font-family:"Arial"; font-weight:300; color:#4e9cc3;',
		'font-size:14px; font-family:"Arial"; font-weight:700; color:#4e9cc3;',
		"color: #4e9cc3;",
		"color: #373737;",
	];
	console.log.apply(console, _cA);
	// credits - end

	// Init tooltip
	$(function () {
		$('[data-toggle="tooltip"]').tooltip();
	});

	//cookies consent
	if ($.cookie("cookie") != 0) {
		$.cookie("cookie", 1);
	}

	$("#button-cookie").click(function () {
		$.cookie("cookie", 0);
		showCookie();
	});

	showCookie();

	function showCookie() {
		if ($.cookie("cookie") == 1) {
			$("#cookieconsent").show();
		} else {
			$("#cookieconsent").hide();
		}
	}

	$("#cookieconsent .cc-dismiss").click(function (e) {
		e.preventDefault();
		$("#cookieconsent").slideToggle(300, function () {
			$(this).remove();
		});
	});
	//end cookies

	// owl carousel
	var owlOne = $("#partners .owl-carousel");

	owlOne.owlCarousel({
		items: 5,
		autoplay: true,
		loop: true,
		margin: 50,
		center: true,
		dots: false,
		nav: true,
		responsive: {
			0: {
				items: 1,
			},
			600: {
				items: 3,
			},
			1000: {
				items: 5,
			},
		},
	});

	$("#partners nav ul li a").click(function (event) {
		owlOne.trigger("to.owl.carousel", [$(this).parent().index(), 300]);
		return false;
	});
	//end carousel

	//Chart
	if ($("#preci-sales").length) {
		var ctx2 = document.getElementById("preci-division").getContext("2d");
		var myPieChart = new Chart(ctx2, {
			type: "doughnut",
			data: {
				labels: [
					"Aéronautique",
					"Automobile",
					"Bâtiment",
					"Agroalimentaire",
					"Armement",
					"Travail des métaux",
					"Autres",
				],
				datasets: [
					{
						label: "Répartition",
						data: [70.9, 18.8, 2.7, 1.8, 1.6, 1.6, 2.6],
						backgroundColor: [
							"#b91f38", // Couleur principale
							"#d64257", // Nuance plus claire de rouge
							"#e76471", // Autre nuance claire
							"#fa8795", // Rose clair
							"#a61b31", // Rouge plus foncé
							"#8c1728", // Rouge très foncé
							"#fcb0b8", // Rose pastel
						],
						borderWidth: 0,
					},
				],
			},
			options: {
				title: {
					display: true,
					fontSize: 24,
					text: "Répartition (en %)",
				},
			},
		});
	}
	// end of chart

	// blackened header on scroll
	function opacityOnScroll() {
		var item = $("#header.border-top-red");
		var scrollVal = $(this).scrollTop();
		var valueOpa = 0.35 + scrollVal / 500;

		if (valueOpa < 1) {
			item.css("background-color", "rgba(0, 0, 0, " + valueOpa + ")");
		} else if (valueOpa >= 1) {
			item.css("background-color", "rgba(0, 0, 0, 1");
		} else {
			return false;
		}
	}

	$(window).on("scroll , load", function () {
		opacityOnScroll();
	});
	//end of blackened

	// toggle hamburger icon
	$(".first-button").on("click", function () {
		$(".animated-icon1").toggleClass("open");
	});
	//end of toggle hamburger

	//function Smooth scrolling to a specific element
	function scrollTo(target) {
		if (target.length) {
			var headerHeight = $("#header").innerHeight();
			$("html, body")
				.stop()
				.animate({ scrollTop: target.offset().top - headerHeight }, 1000);
		}
	}
	// call function
	$(" #machines-tab a").click(function () {
		scrollTo($("#machines-tabContent"));
	});
	// end of smooth scroll
});
